import React from "react";
import OcPageTransition from "oc/components/oc-page-transition";
import Box from "@material-ui/core/Box";
import OcCard from "oc/components/oc-card";
import { useTranslation } from "react-i18next";
import useUserRolesDataByContract from "hooks/use-myhr-user-roles-data-by-contract";
import useCurrentUserState from "hooks/use-current-user-state";
import OcFileViewerAndDownloaderBox from "oc/components/oc-file-viewer-and-downloader-box/oc-file-viewer-and-downloader-box";

export default function MyHrUserGuides() {
  const { t } = useTranslation();
  const { contractCode } = useCurrentUserState();
  const { data: rolesData } = useUserRolesDataByContract({
    contract: contractCode,
  });

  const roles = {
    isMyHrExaminer: false,
    isMyHrAdmin: false,
    isMyLeaveUser: false,
    isMyLeaveAdmin: false,
    isMyLeaveExaminer: false,
    isMyLeaveViewer: false,
    isMyCafeteriaUser: false,
    isMyCafeteriaAdmin: false,
    isMyTimeUser: false,
    isMyTimeExaminer: false,
    isMyTimeAdmin: false,
  };

  rolesData?.forEach((item) => {
    if (item.product === "MyHR") {
      if (item.role === "examiner") roles.isMyHrExaminer = true;
      if (item.role === "admin") roles.isMyHrAdmin = true;
    } else if (item.product === "MyLeave") {
      if (item.role === "user") roles.isMyLeaveUser = true;
      if (item.role === "admin") roles.isMyLeaveAdmin = true;
      if (item.role === "examiner") roles.isMyLeaveExaminer = true;
      if (item.role === "viewer") roles.isMyLeaveViewer = true;
    } else if (item.product === "MyCafeteria") {
      if (item.role === "user") roles.isMyCafeteriaUser = true;
      if (item.role === "admin") roles.isMyCafeteriaAdmin = true;
    } else if (item.product === "MyTime") {
      if (item.role === "user") roles.isMyTimeUser = true;
      if (item.role === "examiner") roles.isMyTimeExaminer = true;
      if (item.role === "admin") roles.isMyTimeAdmin = true;
    }
  });

  return (
    <OcPageTransition>
      <Box>
        <Box
          height="80px"
          style={{ backgroundColor: "#cc0033" }}
          display="flex"
          flexDirection="row"
          alignItems="center"
          paddingLeft="14px"
        >
          <Box style={{ color: "white", fontSize: "24px" }}>
            {t("USER_GUIDES")}
          </Box>
        </Box>
        <Box padding="16px">
          <Box width="520px">
            <OcCard label={t("DOWNLOADS")} loading={false}>
              <>
                <Box>
                  <OcFileViewerAndDownloaderBox
                    label={t("MY_HR_USER_GUIDE")}
                    href="/manual/OrgwareConnect_MyHR_felhasznaloi_kezikonyv.pdf"
                    modul="guides"
                  />
                  <Box height="16px" />
                </Box>
                {roles.isMyHrAdmin && (
                  <Box id="my-hr-user-guides-admin">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_HR_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyHR_adminisztrator_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyHrExaminer && (
                  <Box id="myhr-examiner">
                    <OcFileViewerAndDownloaderBox
                      label={t("MYHR_EXAMINER_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyHR_elbiralo_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyLeaveUser && (
                  <Box id="my-hr-user-guides-leave-user">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_LEAVE_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_felhasznaloi_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyLeaveAdmin && (
                  <Box id="my-hr-user-guides-leave-admin">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_LEAVE_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_adminisztrator_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyLeaveExaminer && (
                  <Box id="my-hr-user-leave-examiner">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_LEAVE_EXAMINER_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_elbiralo_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyLeaveViewer && (
                  <Box id="my-hr-user-leave-viewer">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_LEAVE_VIEWER_GUIDE")}
                      href="/manual/OrgwareConnect_MyLeave_megtekinto_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyCafeteriaAdmin && (
                  <Box id="my-cafeteria-admin">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_CAFETERIA_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyCafeteria_adminisztrator_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyCafeteriaUser && (
                  <Box id="my-cafeteria-user">
                    <OcFileViewerAndDownloaderBox
                      label={t("MY_CAFETERIA_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyCafeteria_felhasznaloi_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyTimeUser && (
                  <Box id="mytime-user">
                    <OcFileViewerAndDownloaderBox
                      label={t("MYTIME_USER_GUIDE")}
                      href="/manual/OrgwareConnect_MyTime_felhasznaloi_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyTimeExaminer && (
                  <Box id="mytime-user">
                    <OcFileViewerAndDownloaderBox
                      label={t("MYTIME_EXAMINER_GUIDE")}
                      href="/manual/OrgwareConnect_MyTime_elbiraloi_kezikonyv.pdf"
                      modul="guides"
                    />
                    <Box height="16px" />
                  </Box>
                )}
                {roles.isMyTimeAdmin && (
                  <Box id="mytime-user">
                    <OcFileViewerAndDownloaderBox
                      label={t("MYTIME_ADMIN_GUIDE")}
                      href="/manual/OrgwareConnect_MyTime_adminisztratori_kezikonyv.pdf"
                      modul="guides"
                    />
                  </Box>
                )}
              </>
            </OcCard>
          </Box>
        </Box>
      </Box>
    </OcPageTransition>
  );
}
