import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/react-hooks";
import Box from "@material-ui/core/Box";
import { useTranslation } from "react-i18next";
import OcCard from "oc/components/oc-card";
import { UPDATE_PROPERTY } from "graphql/mutations";
import { Typography } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import emailIcon from "@iconify/icons-mdi/email";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InlineIcon } from "@iconify/react";
import cellphoneIcon from "@iconify/icons-mdi/cellphone";
import OcCardButtonBar from "oc/components/oc-card-button-bar/oc-card-button-bar";
import useProperty from "hooks/use-oc-property";
import linkVariant from "@iconify/icons-mdi/link-variant";
import Switch from "oc/components/oc-form-switch";
import Collapse from "@material-ui/core/Collapse";
import OcConfirmationDialog from "oc/components/oc-confirmation-dialog";
import useOcSnackbar from "hooks/use-oc-snackbar";

export default function MyHrAdminSystemHelpdeskMenu() {
  const { t } = useTranslation();

  const { setOpenMessage } = useOcSnackbar();

  const [isValid, setIsValid] = useState(true);
  const [checked, setChecked] = useState(false);

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const { data: helpdeskEmail } = useProperty({
    resource: "system",
    key: "helpdeskEmail",
  });

  const { data: helpdeskPhone } = useProperty({
    resource: "system",
    key: "helpdeskPhone",
  });
  const { data: helpdeskURL } = useProperty({
    resource: "system",
    key: "helpdeskURL",
  });

  const { data: helpdeskCustomText } = useProperty({
    resource: "system",
    key: "helpdeskCustomText",
  });

  const [helpdeskEmailTempState, setHelpdeskEmailTempState] =
    useState(helpdeskEmail);
  const [helpdeskPhoneTempState, setHelpdeskPhoneTempState] =
    useState(helpdeskPhone);
  const [helpdeskURLTempState, setHelpdeskURLTempState] = useState(helpdeskURL);

  const [helpdeskCustomTextTempState, setHelpdeskCustomTextTempState] =
    useState(helpdeskCustomText);

  if (
    helpdeskCustomTextTempState === "" ||
    helpdeskCustomTextTempState === null
  ) {
    setHelpdeskCustomTextTempState(t("HELPDESK_CONTACTS_INFO"));
  }

  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    onCompleted: () => {
      setOpenMessage({
        type: "success",
        message: t("PROPERTY_SAVE_SUCCEEDED"),
      });
    },
    onError({ ...err }) {
      alert("error" + err.toString());
      setOpenMessage({
        type: "error",
        message: t("PROPERTY_SAVE_FAILED"),
      });
    },
  });

  //const inputError = !helpdeskEmailTempState && !helpdeskURLTempState;

  function handleSave() {
    /* if (inputError === true) {
      return;
    } else {*/
    if (helpdeskEmail !== helpdeskEmailTempState) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      const isValidEmail =
        emailRegex.test(helpdeskEmailTempState) ||
        helpdeskEmailTempState?.length === 0;

      setIsValid(/*!helpdeskEmailTempState ? true :*/ isValidEmail);
      if (isValidEmail === false /*&& helpdeskEmailTempState?.length !== 0*/) {
        setShowConfirmDialog(false);
        return;
      } else {
        updateProperty({
          variables: {
            resource: "system",
            key: "helpdeskEmail",
            value: helpdeskEmailTempState,
          },
        });
      }
    }
    if (helpdeskCustomText !== helpdeskCustomTextTempState) {
      if (
        helpdeskCustomText !== helpdeskCustomTextTempState &&
        !helpdeskCustomTextTempState
      ) {
        updateProperty({
          variables: {
            resource: "system",
            key: "helpdeskCustomText",
            value: t("HELPDESK_CONTACTS_INFO"),
          },
        });
      } else {
        updateProperty({
          variables: {
            resource: "system",
            key: "helpdeskCustomText",
            value: helpdeskCustomTextTempState,
          },
        });
      }
    }
    if (helpdeskURL !== helpdeskURLTempState) {
      updateProperty({
        variables: {
          resource: "system",
          key: "helpdeskURL",
          value: helpdeskURLTempState,
        },
      });
    }
    if (helpdeskPhone !== helpdeskPhoneTempState) {
      let newState = helpdeskPhoneTempState.toString();
      updateProperty({
        variables: {
          resource: "system",
          key: "helpdeskPhone",
          value: newState,
        },
      });
    }
    //}
    setShowConfirmDialog(false);
  }

  //We have not found a better solution for this (20230511)
  function handleCancel() {
    setHelpdeskEmailTempState(helpdeskEmail);
    document.getElementById("email-input").value = helpdeskEmail;
    setHelpdeskPhoneTempState(helpdeskPhone);
    document.getElementById("phone-input").value = helpdeskPhone;
    setHelpdeskURLTempState(helpdeskURL);
    document.getElementById("url-input").value = helpdeskURL;
    setHelpdeskCustomTextTempState(helpdeskCustomText);
    document.getElementById("custom-text-input").value = helpdeskCustomText;
  }

  let disabledHelpdesk = true;

  if (
    helpdeskEmail !== helpdeskEmailTempState ||
    helpdeskURL !== helpdeskURLTempState ||
    helpdeskPhone !== helpdeskPhoneTempState ||
    helpdeskCustomText !== helpdeskCustomTextTempState
  ) {
    disabledHelpdesk = false;
  }

  if (
    helpdeskPhone === helpdeskPhoneTempState?.toString() &&
    helpdeskURL === helpdeskURLTempState &&
    helpdeskEmail === helpdeskEmailTempState &&
    helpdeskCustomText === helpdeskCustomTextTempState
  ) {
    disabledHelpdesk = true;
  }

  useEffect(() => {
    if (!helpdeskEmailTempState) {
      setHelpdeskEmailTempState(helpdeskEmail);
      document.getElementById("email-input").value = helpdeskEmail;
    } else if (helpdeskEmail?.length === 0) {
      setHelpdeskEmailTempState("");
    }
    if (!helpdeskPhoneTempState) {
      setHelpdeskPhoneTempState(helpdeskPhone);
      document.getElementById("phone-input").value = helpdeskPhone;
    } else if (helpdeskPhone?.length === 0) {
      setHelpdeskPhoneTempState("");
    }
    if (!helpdeskURLTempState) {
      setHelpdeskURLTempState(helpdeskURL);
      document.getElementById("url-input").value = helpdeskURL;
    } else if (helpdeskURLTempState?.length === 0) {
      setHelpdeskURLTempState("");
    }
    if (!helpdeskCustomTextTempState) {
      setHelpdeskCustomTextTempState(helpdeskCustomText);
      document.getElementById("custom-text-input").innerHTML =
        helpdeskCustomText;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helpdeskEmail, helpdeskPhone, helpdeskURL, helpdeskCustomText]);

  function handleDialogCancel() {
    setShowConfirmDialog(false);
  }

  return (
    <>
      <OcConfirmationDialog
        showDialog={showConfirmDialog}
        onClose={handleSave}
        onCancel={handleDialogCancel}
        title={t("CONFIRM_SETTING")}
        text={t("CONFIRM_SETTING_INFO")}
        okButtonText={t("ALL_RIGHT")}
        cancelButtonText={t("CANCEL")}
      />

      <OcCard label={t("HELPDESK_CONTACTS")}>
        <Box display="flex" flexDirection="column" padding="16px 32px">
          <TextField
            id="email-input"
            label={t("EMAIL")}
            variant="outlined"
            InputLabelProps={{
              shrink: helpdeskEmailTempState?.length > 0 ? true : false,
            }}
            defaultValue={helpdeskEmailTempState}
            onChange={(e) => setHelpdeskEmailTempState(e.target.value)}
            style={{ width: 432 }}
            error={!isValid /*|| inputError*/}
            helperText={!isValid && t("ERROR_MESSAGE_WRONG_EMAIL_FORMAT")}
            onFocus={() => {
              setIsValid(true);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end" >
                  <InlineIcon height="24px" icon={emailIcon} />
                </InputAdornment>
              ),
            }}
          />
          <Box height="32px" />
          {/*      <Box>{t("OR")}</Box>
          <Box height="32px" />*/}
          <TextField
            id="url-input"
            label={t("HELPDESK_URL")}
            variant="outlined"
            defaultValue={helpdeskURLTempState}
            InputLabelProps={{
              shrink: helpdeskURLTempState?.length > 0 ? true : false,
            }}
            onChange={(e) => setHelpdeskURLTempState(e.target.value)}
            style={{ width: 432 }}
            //error={inputError}
            //helperText={inputError && t("AT_LEAST_ONE_FIELD_IS_REQUIRED")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineIcon height="24px" icon={linkVariant} />
                </InputAdornment>
              ),
            }}
          />
          <Box height="36px" />
          <Box
            style={{
              width: "432px",
              borderBottom: "1px solid #C6CACC",
            }}
          />
          <Box height="36px" />
          <TextField
            id="phone-input"
            label={t("PHONE")}
            variant="outlined"
            type="number"
            defaultValue={helpdeskPhoneTempState}
            helperText={t("OPTIONAL")}
            onChange={(e) => setHelpdeskPhoneTempState(e.target.value)}
            style={{ width: 432 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <InlineIcon height="24px" icon={cellphoneIcon} />
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position="start">+36</InputAdornment>
              ),
            }}
          />
          <Box height="36px" />
          <Box
            style={{
              width: "432px",
              borderBottom: "1px solid #C6CACC",
            }}
          />
          <Box height="36px" />
          <Box display="flex" flexDirection="row" alignItems="center">
            <Switch
              id="customHelpdeskText"
              checked={checked}
              onChange={(e) => setChecked(e.target.checked)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Box width="16px" />
            <Typography variant="body1">
              {t("HELPDESK_CUSTOM_INFO_TEXT")}
            </Typography>
          </Box>
          <Collapse in={checked} timeout="auto">
            <Box height="36px" />
            <TextField
              id="custom-text-input"
              multiline={true}
              label={t("CUSTOM_TEXT")}
              variant="outlined"
              InputLabelProps={{
                shrink: helpdeskCustomTextTempState?.length > 0 ? true : false,
              }}
              defaultValue={helpdeskCustomTextTempState}
              onChange={(e) => setHelpdeskCustomTextTempState(e.target.value)}
              style={{ width: 432, height: "auto" }}
              helperText={
                !helpdeskCustomTextTempState
                  ? `(${t("Common.optional")})`
                  : `${helpdeskCustomTextTempState.length}/255`
              }
              inputProps={{ maxLength: 255 }}
              FormHelperTextProps={
                !helpdeskCustomTextTempState
                  ? { style: { textAlign: "left" } }
                  : { style: { textAlign: "right" } }
              }
            />
          </Collapse>
        </Box>
        <OcCardButtonBar
          handleRightButton={handleCancel}
          rightButtonText={t("CANCEL")}
          handlePrimaryButton={() => setShowConfirmDialog(true)}
          primaryButtonText={t("SAVE")}
          primaryButtonDisabled={disabledHelpdesk}
        />
      </OcCard>
    </>
  );
}
