import { createTheme } from "@material-ui/core/styles";
import { huHU } from "@material-ui/core/locale";
import i18n from "i18next";

const lang =
  i18n && i18n?.language ? i18n.language.substr(0, 2).toLowerCase() : "hu";

const colors = {
  beigeDark: "#79796E",
  beigeLight: "#BAB0AC",
  blueBase: "#367FA9",
  blueGray: "#78909C",
  blueLight: "#A0CBE8",
  brownDark: "#9D7660",
  darkBase: "#212121",
  gray10: "#F7F9FA",
  gray20: "#F1F3F4",
  gray30: "#E3E5E5",
  gray40: "#C6CACC",
  gray50: "#A6ADAF",
  gray60: "#919699",
  gray80: "#5E6366",
  gray90: "#3E4345",
  greenBase: "#2FA84F",
  greenLight: "#8CD17D",
  pinkLight: "#FABFD2",
  primary: "#CC0033",
  purpleDark: "#B07AA1",
  purpleLight: "#D4A6C8",
  redLight: "#FF9D9A",
  redMid: "#E15759",
  redSmooth: "#FEE4E2",
  tealBase: "#069697",
  turkizLight: "#86BCB6",
  white: "#ffffff",
  yellowBase: "#F3AA18",
  yellowDark: "#B6992D",
  owGray: "#4D4D4D",
};

const chartColors = {
  red: "#DF1125",
  pink: "#FD4154",
  dragonFruit: "#F12E6D",
  camellia: "#BF3138",
  redRose: "#AB1438",
  purple: "#8962F8",
  ultraViolet: "#5F41B2",
  lilac: "#A990DD",
  lavenderGray: "#757397",
  lavender: "#A58998",
  cocoa: "#8B7D7D",
  cream: "#FFE0AB",
  mellowYellow: "#F7F6AF",
  canaryYellow: "#FFDA3A",
  pollen: "#FECB4D",
  yellow: "#E8C511",
  lemonCream: "#FFEB6D",
  flash: "#DFF81E",
  blue: "#1EA5FC",
  lightBlue: "#5EBAD9",
  cerulian: "#87B9E7",
  surfBlue: "#1871AC",
  denimBlue: "#4B709A",
  orange: "#FC4E12",
  papaya: "#FD7036",
  fuguOrange: "#FEAA0A",
  lightOrange: "#FD820B",
  peach: "#E0694E",
  flamingo: "#CA6F59",
  roseGold: "#E99475",
  pinkSand: "#FEB69C",
  lightPink: "#F19F9D",
  grapefruit: "#FDA27E",
  green: "#80E220",
  mint: "#A2EC8E",
  beryl: "#D0F2B1",
  seaFoam: "CFF1D6",
  turquoise: "#91CEC2",
  stormGray: "#578887",
  mistBlue: "#A7AB99",
  darkOlive: "#7C7A66",
  softWhite: "#D8C9AF",
  camel: "#A97D4F",
  pebble: "#9F8D7E",
  coastalGray: "#715D50",
};

const owTheme = createTheme(
  {
    typography: {
      fontFamily: ["Mulish", "Roboto"],
      button: {
        fontWeight: 400,
        letterSpacing: "0.75px",
        fontSize: "14px",
        lineHeight: "18px",
      },
      h1: {
        fontSize: "96px",
        lineHeight: "120px",
        letterSpacing: "-1.5px",
      },
      h2: {
        fontSize: "60px",
        lineHeight: "75px",
      },
      h3: {
        fontSize: "48px",
        lineHeight: "60px",
      },
      h4: {
        fontSize: "34px",
        lineHeight: "43px",
        letterSpacing: "0.25px",
        color: colors.darkBase,
        "@media print": {
          fontSize: "23px",
          lineHeight: "29px",
        },
      },
      h5: {
        fontSize: "24px",
        lineHeight: "30px",
        color: colors.darkBase,
        "@media print": {
          fontSize: "16px",
          lineHeight: "20px",
        },
      },
      h6: {
        fontSize: "20px",
        lineHeight: "28px",
        letterSpacing: "0.15px",
        "@media print": {
          fontSize: "14px",
          lineHeight: "18px",
        },
      },
      body1: {
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
        "@media print": {
          fontSize: "11px",
          lineHeight: "16px",
        },
      },
      body2: {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.44px",
        "@media print": {
          fontSize: "10px",
          lineHeight: "11px",
        },
      },
      caption: {
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
      },
    },
    palette: {
      primary: {
        main: colors.primary,
      },
      gray10: {
        main: colors.gray10,
      },
      gray20: {
        main: colors.gray20,
      },
      gray30: {
        main: colors.gray30,
      },
      gray40: {
        main: colors.gray40,
      },
      gray50: {
        main: colors.gray50,
      },
      gray60: {
        main: colors.gray60,
      },
      gray80: {
        main: colors.gray80,
      },
      gray90: {
        main: colors.gray90,
      },
      darkBase: {
        main: colors.darkBase,
      },
      blueGray: {
        main: colors.blueGray,
      },
      white: {
        main: colors.white,
      },
      blueBase: {
        main: colors.blueBase,
      },
      blueLight: {
        main: colors.blueLight,
      },
      yellowBase: {
        main: colors.yellowBase,
      },
      greenBase: {
        main: colors.greenBase,
      },
      tealBase: {
        main: colors.tealBase,
      },
      turkizLight: {
        main: colors.turkizLight,
      },
      greenLight: {
        main: colors.greenLight,
      },
      redMid: {
        main: colors.redMid,
      },
      redLight: {
        main: colors.redLight,
      },
      pinkLight: {
        main: colors.pinkLight,
      },
      purpleDark: {
        main: colors.purpleDark,
      },
      purpleLight: {
        main: colors.purpleLight,
      },
      beigeDark: {
        main: colors.beigeDark,
      },
      beigeLight: {
        main: colors.beigeLight,
      },
      brownDark: {
        main: colors.brownDark,
      },
      yellowDark: {
        main: colors.yellowDark,
      },
      owGray: {
        main: colors.owGray,
      },
    },
    chartPalette: {
      red: {
        main: chartColors.red,
      },
      pink: {
        main: chartColors.pink,
      },
      dragonFruit: {
        main: chartColors.dragonFruit,
      },
      camellia: {
        main: chartColors.camellia,
      },
      redRose: {
        main: chartColors.redRose,
      },
      purple: {
        main: chartColors.purple,
      },
      ultraViolet: {
        main: chartColors.ultraViolet,
      },
      lilac: {
        main: chartColors.lilac,
      },
      lavenderGray: {
        main: chartColors.lavenderGray,
      },
      lavender: {
        main: chartColors.lavender,
      },
      cocoa: {
        main: chartColors.cocoa,
      },
      cream: {
        main: chartColors.cream,
      },
      mellowYellow: {
        main: chartColors.mellowYellow,
      },
      canaryYellow: {
        main: chartColors.canaryYellow,
      },
      pollen: {
        main: chartColors.pollen,
      },
      yellow: {
        main: chartColors.yellow,
      },
      lemonCream: {
        main: chartColors.lemonCream,
      },
      flash: {
        main: chartColors.flash,
      },
      blue: {
        main: chartColors.blue,
      },
      lightBlue: {
        main: chartColors.lightBlue,
      },
      cerulian: {
        main: chartColors.cerulian,
      },
      surfBlue: {
        main: chartColors.surfBlue,
      },
      denimBlue: {
        main: chartColors.denimBlue,
      },
      orange: {
        main: chartColors.orange,
      },
      papaya: {
        main: chartColors.papaya,
      },
      fuguOrange: {
        main: chartColors.fuguOrange,
      },
      lightOrange: {
        main: chartColors.lightOrange,
      },
      peach: {
        main: chartColors.peach,
      },
      flamingo: {
        main: chartColors.flamingo,
      },
      roseGold: {
        main: chartColors.roseGold,
      },
      pinkSand: {
        main: chartColors.pinkSand,
      },
      lightPink: {
        main: chartColors.lightPink,
      },
      grapefruit: {
        main: chartColors.grapefruit,
      },
      green: {
        main: chartColors.green,
      },
      mint: {
        main: chartColors.mint,
      },
      beryl: {
        main: chartColors.beryl,
      },
      seaFoam: {
        main: chartColors.seaFoam,
      },
      turquoise: {
        main: chartColors.turquoise,
      },
      stormGray: {
        main: chartColors.stormGray,
      },
      mistBlue: {
        main: chartColors.mistBlue,
      },
      darkOlive: {
        main: chartColors.darkOlive,
      },
      softWhite: {
        main: chartColors.softWhite,
      },
      camel: {
        main: chartColors.camel,
      },
      pebble: {
        main: chartColors.pebble,
      },
      coastalGray: {
        main: chartColors.coastalGray,
      },
    },
    props: {
      MuiBadge: {
        overlap: "rectangular",
      },
    },
    overrides: {
      MuiButton: {
        root: {
          height: 40,
        },
        text: {
          padding: "0px 22px",
        },
        textSecondary: {
          color: colors.primary,
        },
        containedPrimary: {
          backgroundColor: colors.primary,
        },
        containedSecondary: {
          backgroundColor: colors.white,
        },
      },
      MuiTextField: {
        root: {
          height: "56px",
          background: "transparent",
          margin: 0,
          fontSize: "16px",
          letterSpacing: "0.44px",
        },
      },
      MuiFormHelperText: {
        root: {
          "&$error": {
            color: colors.primary,
          },
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: "16px",
          letterSpacing: 0.44,
          color: colors.gray80,
          "&$focused": {
            color: colors.gray80,
          },
          "&$error": {
            color: colors.primary,
          },
        },
      },
      MuiOutlinedInput: {
        root: {
          "&$focused $notchedOutline": {
            borderColor: colors.gray40,
            borderWidth: "1px",
          },
          "&$error": {
            borderColor: colors.primary,
          },
        },
      },
      MuiInputAdornment: {
        root: {
          padding: "2px",
          color: colors.gray60,
        },
      },
      MuiTab: {
        root: {
          backgroundColor: colors.gray30,
          textTransform: "initial",
          fontSize: "16px",
          lineHeight: "20px",
          letterSpacing: "0.44px",
          height: "56px",
          "&$selected": {
            backgroundColor: colors.white,
            borderBottom: "4px solid " + colors.primary,
          },
        },
        textColorPrimary: {
          color: colors.gray80,
          "&$selected": {
            color: colors.darkBase,
          },
        },
      },
      MuiDrawer: {
        paper: {
          backgroundColor: colors.darkBase,
        },
        paperAnchorDockedLeft: {
          borderRight: 0,
        },
      },
      MuiTableCell: {
        head: {
          fontSize: "16px",
          letterSpacing: "0.25px",
          color: colors.gray80,
          padding: "10px 8px 10px 8px",
          backgroundColor: colors.gray30,
          "&:last-child": {
            borderTopRightRadius: "4px",
            borderBottomRightRadius: "4px",
          },
          "&:first-child": {
            borderTopLeftRadius: "4px",
            borderBottomLeftRadius: "4px",
          },
        },
        stickyHeader: {
          backgroundColor: colors.gray30,
        },
        body: {
          fontSize: "16px",
          letterSpacing: "0.44px",
          padding: "14px 8px 14px 8px",
          color: colors.gray80,
        },
      },
      MuiCheckbox: {
        colorSecondary: {
          "&$checked": { color: colors.gray60 },
        },
      },
      MuiAlert: {
        message: {
          fontSize: "16px",
          letterSpacing: "0.25px",
        },
        icon: {
          fontSize: "24px",
        },
        filledError: {
          backgroundColor: colors.primary,
        },
        filledSuccess: {
          backgroundColor: colors.greenBase,
        },
      },
    },
  },
  lang === "hu" ? huHU : null
);

export default owTheme;
